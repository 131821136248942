@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  margin: 0;
}

input[type="checkbox"] {
  accent-color: transparent;
  /* For modern browsers */
  background-color: #ffffff;
  /* White background */
  border: 1px solid #d1d5db;
  /* Gray border */
}

input[type="checkbox"]:checked {
  background-color: #4caf50; /* Green background */
  border-color: #4caf50; /* Matching border color */
}